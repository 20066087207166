#barraaceptacion {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    @include rem(padding, 20px);
    width: 100%;
    text-align: center;
    background-color: rgba($neutro, .95);
    color: $white;
    z-index: 99999;
}

.inner {
    p {
        @include rem(font-size, 16px);
    }
    a {
        &.ok {
            display: inline-block;
            padding: 8px 16px;
            color: $neutro;
            font-weight: 700;
            background: $secondary2;
            @extend %transition;
            border-radius: 5px;
        }
        &.info {
            display: inline-block;
            @include rem(margin-left, 16px);
            color: $white;
        }
    }
    &__buttons {
        display: block;
        text-align: center;
        @include rem(margin-top, 10px);
    }
}

input[type=submit] {
    border: none;
    cursor: pointer;
}

button {
    cursor: pointer;
    font-family: $font-base;
}

input[type=text],
textarea {
    font-family: $font-base;
    @include rem(font-size, 14px);
    padding: 16px 8px;
    border-radius: 4px;
    border: 1px solid $grey;
    width: 100%;
    color: $neutro;

    &::placeholder {
        color: $grey;
    }
}

.field__wrapper {
    display: block;
    @include rem(margin-bottom, 24px);

    input, 
    textarea {
        width: 100%;
        border-radius: 4px;
        padding: 16px 8px;
        @include rem(font-size, 14px);
    }

    &.-flex {
        input + input {
            @include rem(margin-top, 24px);
        }
    } 

    // Tablet
    @include mq($from: tablet) {
        &.-flex {
            display: flex;
            justify-content: space-between;
            input {
                width: 48.5%;
            }
            input + input {
                margin-top: 0;
            }
        }
    }
}
@import 'settings/variables';

@import 'generic/reset';

@import 'tools/mixins';
@import 'tools/mq';

@import 'components/components';
@import 'components/form';
@import 'components/modal';
@import 'components/cookies';
@import 'generic/base';
@import 'components/banner';
@import 'components/banner-form';
@import 'components/banner-image';
@import 'components/block-info';
@import 'components/block-list';
@import 'components/block-column';
@import 'components/block-video';
@import 'components/block-prices';
@import 'components/category-card';

@import 'layout/body';
@import 'layout/header';
@import 'layout/main';
@import 'layout/navigation';
@import 'layout/footer';
@import 'layout/section';

@import 'layout/innerPage';
@import 'layout/homePage';
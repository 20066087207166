.banner {
    color: $neutro;
    transition: all 0.3s ease-in-out;

    &__header {
        @include rem(min-height, 200px);
        padding: 16px 8px;
        // background: yellow;
        text-align: center;
        display: table;
        width: 100%;

        &__content {
            display: table-cell;
            vertical-align: middle;
        }

        &__title {
            transition: all 0.3s ease-in-out;
            color: $white;
            font-weight: 700;
            @include rem(font-size, 24px);
            @include rem(margin-bottom, 8px);
        }
    }
    &__liberar {
        .banner__header {
            background: url('../img/bg-liberar.png') no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    &__rentabilizar {
        .banner__header {
            background: url('../img/bg-rentabilizar.png') no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    &__content {
        text-align: center;
        padding: 16px 32px;

        &__title {
            font-weight: 700;
            @include rem(font-size, 24px);
            @include rem(line-height, 30px);
            @include rem(margin-bottom, 8px);
        }
        &__text {
            color: $black;
            @include rem(font-size, 14px);
            @include rem(line-height, 20px);
        }
    }

    // Tablet
    @include mq($from: tablet) {
        &__header {
            min-height: 30vh;
            @include rem(padding, 32px);

            &__content {
                vertical-align: bottom;
            }
            &__title {
                @include rem(font-size, 32px);
                @include rem(margin-bottom, 24px);
                max-width: 50%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        &__content {
            padding: 24px 32px;
            &__title {
                @include rem(font-size, 24px);
            }
            &__text {
                @include rem(font-size, 16px);
            }
        }
    }

    // Desktop
    @include mq($from: desktop) {
        width: 50%;

        // Para el efecto de selección
        &.active {
            width: 70%;
        }
        &.noactive {
            .banner__header__title {
                @include rem(font-size, 40px);
                @include rem(line-height, 56px);
            }
        }

        &__header {
            min-height: 70vh;
            @include rem(padding-bottom, 132px);
            &__title {
                @include rem(font-size, 56px);
                @include rem(line-height, 64px);
                @include rem(margin-bottom, 32px);
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        &__content {
            @include rem(max-width, 320px);
            margin: auto;
            &:after {
                content: "";
                display: block;
                width: 50%;
                height: 1px;
                background: $primary;
                margin: auto;
                @include rem(margin-top, 64px);
            }
            &__title {
                @include rem(font-size, 32px);
                @include rem(line-height, 40px);
            }
            &__text {
                @include rem(font-size, 18px);
                @include rem(line-height, 30px);
            }
        }
    }
}

.home__banners {
    // Desktop
    @include mq($from: desktop) {
        display: flex;
    }
}
.block__info {
    margin: 0 8px 56px;
    &:after {
        content: "";
        display: inline-block;
        width: 50%;
        @include rem(margin-top, 32px);
        height: 2px;
        background: $primary;
        transform: translateX(50%);
    }
    &__title {
        @include rem(font-size, 32px);
        @include rem(line-height, 40px);
        @include rem(margin-bottom, 32px);
        font-weight: 700;
        text-align: center;
    }
    &__text {
        @include rem(font-size, 18px);
        @include rem(line-height, 32px);
    }

    // Tablet
    @include mq($from: tablet) {
        &:after {
            width: 30%;
            transform: translateX(115%);
        }
    }

    // Desktop 1200
    @include mq($from: desktop_wider) {
        @include rem(margin-bottom, 80px);

        &:after {
            @include rem(margin-top, 56px);
            width: 10%;
            transform: translateX(450%);
        }

        &.introduction {
            .container {
                @include rem(max-width, 700px);
                margin-left: auto;
                margin-right: auto;
            }

            .block__info__title {
                @include rem(font-size, 32px);
                @include rem(line-height, 40px);
                @include rem(margin-bottom, 32px);
            }
        }

        &__title {
            @include rem(font-size, 48px);
            @include rem(line-height, 56px);
            @include rem(margin-bottom, 72px);
        }

    }


}
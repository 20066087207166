.block__info {
    &.prices {
        // Tablet
        @include mq($from: tablet) {
            .row {
                display: flex;
                justify-content: space-between;
            }
            .col {
                width: 48%;
            }
        }
        // Desktop
        @include mq($from: desktop) {
            .col {
                width: 50%;
            }
            .category_item {
                margin-left: auto;
                margin-right: auto;
            }
        }
        // Desktop wider
        @include mq($from: desktop_wider) {
            .col {
                &.cards {
                    width: 70%;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .category_item {
                width: 48%;
            }
        }
    }
}
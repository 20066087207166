body {
    font-family: $font-base;
    font-weight: 300;
    color: $neutro;
    background: $white;
    font-size: 16px;
}

a {
    text-decoration: none;
}

.container {
    // width: 1176px;
    width: 1220px;
    max-width: 100%;
    margin: 0 auto;
    // padding: 0 8px;

    // Tablet
    @include mq($from: tablet) {
        padding: 0 24px;
    }
    // Desktop
    @include mq($from: desktop) {
        padding: 0;
    }
}


.Franquicia {
    main {
        p {
            margin-bottom: 1.5rem;
        }
        .container {
            // max-width: 850px;
        }
    }

    // Desktop
    @include mq($from: desktop) {
        main {
            .container {
                max-width: 850px;
            }
        }
    }
}

.gracias__icon {
    margin: 1rem auto;
    width: 50px;
    height: 50px;
    fill: $secondary1;
    // Desktop
    @include mq($from: desktop) {
        margin: 0;
    }
}

.gracias-title__wrapper {
    // Desktop
    @include mq($from: desktop) {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
}

.Gracias {

    .title {
        margin-bottom: 2rem;
    }

    main {
        p {
            margin-bottom: 1.5rem;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    // Desktop
    @include mq($from: desktop) {
        .title {
            margin-bottom: 0;
            margin-left: 1rem;
        }
        main {
            .container {
                max-width: 850px;
            }
        }
        .header__text {
            .container {
                max-width: 850px;
            }
        }
        .field__wrapper {
            .button {
                border-radius: 5px;
                padding: 16px 8px;
                font-size: 1.25rem;
                display: inline-block;
                text-align: center;
            }
        }
        
    }

}

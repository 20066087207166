button,
select {
    background: transparent;
    border: none;
    height: 100%;
}

.button {
    @include rem(font-size, 20px);
    @include rem(min-width, 217px);
    padding: 12px 4px;
    @include rem(margin-right, 8px);
    color: $black;
    border-radius: 4px;
    font-weight: 700;
    display: inline-block;
    text-align: center;

    &__primary {
        background: $secondary2;
    }
    &__secondary {
        background: $grey;
    }
}

.select {
    @include rem(font-size, 10px);

    &__wrapper {
        background: $grey;
        @include rem(border-radius, 15px);
    }

    &__language {
        @include rem(min-width, 60px);
        @include rem(padding, 4px);
    }
}

.title {
    // text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    &__principal {
        @include rem(line-height, 24px);
        font-weight: 700;
        @include rem(margin-bottom, 24px);
        > span {
            display: block;
        }
    }
    &__secondary {
        @include rem(font-size, 14px);
        @include rem(line-height, 16px);
        @include rem(margin-bottom, 24px);
        > span {
            display: block;
        }
    }
    &__inner {
        @include rem(font-size, 28px);
        @include rem(line-height, 32px);
        color: $secondary1;
        font-weight: 700;
    }

    // Tablet
    @include mq($from: tablet) {
        &__principal {
            line-height: 2.5rem;
            @include rem(margin-bottom, 32px);
        }
        &__secondary {
            @include rem(font-size, 24px);
            line-height: 2rem;
            @include rem(margin-bottom, 64px);
        }
    }
    // Desktop
    @include mq($from: desktop) {
        &__principal {
            @include rem(line-height, 48px);
            @include rem(margin-bottom, 32px);
        }
        &__secondary {
            @include rem(font-size, 24px);
            @include rem(line-height, 42px);
            @include rem(margin-bottom, 64px);
            > span {
                display: inline-block;
            }
            span + span {
                margin-left: 5px;
            }
        }
    }
}

p {
    @include rem(font-size, 18px);
    @include rem(line-height, 24px);
}

strong {
    font-weight: 700;
}

.text {

    &__upper {
        display: block;
        @include rem(font-size, 20px);
    }
    &__lower {
        display: block;
        @include rem(font-size, 16px);
        text-transform: uppercase;
        font-weight: 500;
    }
    &__highlight {
        &.-type1 {
            color: $secondary2;
            font-style: italic;
            text-transform: uppercase;
        }
        &.-type2 {
            @include rem(font-size, 20px);
        }
        &.-type3 {
            color: $secondary1;
            font-weight: 700;
        }
    }
    // Tablet
    @include mq($from: tablet) {
        &__upper {
            @include rem(font-size, 32px);
        }
        &__lower {
            @include rem(font-size, 24px);
        }
        &__highlight {
            &.-type1 {
            }
            &.-type2 {
                font-size: 2rem;
            }
            &.-type3 {
                
            }
        }
    }
    // Desktop
    @include mq($from: desktop) {
        &__upper {
            @include rem(font-size, 48px);
        }
        &__lower {
            @include rem(font-size, 32px);
        }
        &__highlight {
            &.-type1 {
            }
            &.-type2 {
                @include rem(font-size, 40px);
            }
            &.-type3 {
                
            }
        }
    }
}

.buttons__wrapper {
    text-align: center;

    > a {
        display: inline-block;
    }
    a + a {
        @include rem(margin-left, 16px);
    }
}

.buttons__contact {
    text-align: left;
    padding: 16px 40px;
}

.link {
    &__arrow {
        color: $white;
        display: block;
        @include rem(font-size, 20px);
        @include rem(margin-top, 60px);

        .icon {
            display: block;
            width: 35px;
            height: 13px;
            margin: 12px auto 0;
        }
    }
    &__franquicia {
        text-align: center;
        width: 100%;
        display: block;
        text-decoration: none;
        @include rem(font-size, 24px);
        @include rem(line-height, 32px);
        font-weight: 700;
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        i {
            font-style: italic;
            color: $secondary2;
            text-transform: uppercase;
            display: block;
        }
    }

    // Tablet
    @include mq($from: tablet) {
        &__franquicia {
            @include rem(font-size, 32px);
            i {
                display: inline-block;
            }
        }
    }
    // Desktop
    @include mq($from: desktop) {
        &__franquicia {
            @include rem(font-size, 48px);
        }
    }
}

.highlight {
    &__list {
        border-top: 1px dashed $white;
        @include rem(margin-top, 24px);
    }
    &__item {
        @include rem(margin-top, 24px);
    }
    &__icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
    &__title {
        color: $secondary2;
        font-weight: 700;
        @include rem(margin-bottom, 18px);
    }

    // Desktop
    @include mq($from: desktop) {
        &__title {
            @include rem(font-size, 24px);
        }
        &__icon {
            vertical-align: baseline;
        }
    }
}

.feature {
    &__wrapper {
        text-align: center;
        @include rem(margin-top, 32px);

        &.-autonomos {
            color: $secondary1;
        }
    }
    &__icon {
        width: 120px;
        height: 130px;
        @include rem(margin-bottom, 32px);
    }
    &__check {
        width: 18px;
        height: 18px;
        // display: inline-block;
        // vertical-align: middle;
        @include rem(margin-right, 12px);
        @include rem(margin-top, 4px);
        fill: $primary;
        flex: 0 0 auto;
    }
    &__title {
        @include rem(font-size, 28px);
        font-weight: 700;
        @include rem(margin-bottom, 8px);
    }
    &__text {
        @include rem(font-size, 14px);
        @include rem(line-height, 20px);
        max-width: 70%;
        margin: auto;
        @include rem(margin-bottom, 32px);
    }
    &__list {
        text-align: left;
        @include rem(margin-top, 32px);
        @include rem(margin-bottom, 32px);
    }
    &__item {
        display: flex;
        @include rem(line-height, 24px);
        + .feature__item {
            @include rem(margin-top, 10px);
        }
    }

    // Tablet
    @include mq($from: tablet) {
        &__row {
            display: flex;
        }
        &__wrapper {
            width: 50%;
        }
    }

    // Desktop
    @include mq($from: desktop) {
        &__wrapper {
            margin-top: 0;
        }
        &__text {
            max-width: 80%;
        }
    }

    // Desktop wider
    @include mq($from: desktop_wider) {
        &__list {
            @include rem(margin-bottom, 40px);
        }
    }

}

.video {
    &__wrapper {
        // border: 2px solid $secondary1;
        @include rem(min-height, 222px);
        position: relative;

        iframe {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
        }
    }
    &__text {
        @include rem(font-size, 16px);
        @include rem(line-height, 20px);
        font-weight: 700;
        text-align: center;
        @include rem(margin-top, 22px);
        // @include rem(margin-bottom, 60px);
    }

    // Tablet
    @include mq($from: tablet) {
        &__wrapper {
            min-height: 10rem;
        }
    }

    // Desktop
    @include mq($from: desktop) {
        &__wrapper {
            @include rem(min-height, 300px);
        }
        &__text {
            @include rem(font-size, 24px);
            // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        }
    }
}

.logo-sm {
    width: 134px;
    height: 30px;
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -1px;

    // Desktop
    @include mq($from: desktop) {
        width: 200px;
        height: 46px;
        margin-bottom: -3px;
    }
}

.logo-symbol {
    svg {
        width: 75px;
        height: 35px;
    }
}

.banner {
    &__image {
        display: block;
        margin: auto;
    }
}

.rules {
    &__list {

    }
    &__item {
        display: flex;
        align-items: center;
        p {
            @include rem(font-size, 20px);
            font-weight: 700; 
            @include rem(margin-left, 16px);
        }
        + .rules__item {
            @include rem(margin-top, 24px);
        }
    }
    // Desktop
    @include mq($from: desktop) {
        &__item {
            p {
                @include rem(font-size, 24px);
                max-width: 60%;
            }
        }
    }
}

.security {
    &__item {
        display: flex;

        + .security__item {
            @include rem(margin-top, 20px);
        }
    }
    &__content {
        @include rem(margin-left, 16px);
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $secondary3;

        svg {
            width: 28px;
            height: 28px;
        }
    }
    &__title {
        @include rem(font-size, 20px);
        @include rem(line-height, 24px);
        @include rem(margin-bottom, 8px);
        font-weight: 700; 
    }
    &__text {
        @include rem(font-size, 14px);
        @include rem(line-height, 24px);
    }
    
    // Desktop
    @include mq($from: desktop) {
        &__title {
            margin-bottom: 8px;
        }
    }

    // Desktop wider
    @include mq($from: desktop_wider) {
        &__list {
            &.column {
                display: flex;
                flex-wrap: wrap;

                .security__item {
                    width: 50%;
                    margin-top: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }
}

.schedule {
    @include rem(margin-bottom, 24px);
    small {
        display: block;
        @include rem(font-size, 14px);
        @include rem(line-height, 20px);
    }
}

.email {
    display: flex;
    align-items: center;
    margin: 24px 0;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        width: 48px;
        height: 48px;
        background: $secondary1;
        border-radius: 5px;
        svg {
            width: 27px;
            height: 20px;
        }
    }
    &__text {
        @include rem(margin-left, 30px);
        > * {
            display: block;
        }
    }
    &__title {
        font-weight: 700;
    }
    &__link {
        text-decoration: none;
        color: $neutro;
    }
}

.phone {
    display: flex;
    align-items: center;
    margin: 24px 0;
    &__icon {
        display: block;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background: $secondary3;
        svg {
            width: 30px;
            height: 30px;
            margin: .68rem;
        }
    }
    &__text {
        @include rem(margin-left, 30px);
        > * {
            display: block;
        }
    }
    &__title {
        font-weight: 700;
    }
    &__link {
        text-decoration: none;
        color: $neutro;
        font-weight: 700;
    }
}

.other__links {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    &__wrap {
        background: #000000;
        padding: .5rem;
    }

    a {
        color: $secondary1;
        display: block;
        @include rem(font-size, 16px);
        padding: 1rem 0;

        &.text__highlight {
            text-decoration: underline;
        }
    }

    .copy {
        position: absolute;
        left: .5rem;
        bottom: 1.5rem;
        font-size: 1rem;
        line-height: 1;
        font-weight: 700;
    }

    .phone {
        // padding: 16px 40px;
        padding: 16px;
        margin: 0;

        &__text {
            text-align: left;
            margin-left: 1rem;
        }
        &__title {
            color: $secondary1;
        }
        &__link {
            color: $white;
            padding: 0;
        }
    }
}

/*
.phone {
    &__wrapper {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
    }
    &__icon {
        display: block;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background: $secondary3;
        svg {
            width: 30px;
            height: 30px;
            margin: .68rem;
        }
    }
    &__text {
        font-weight: 700;
        color: $secondary1;
        @include rem(margin-left, 16px);
        @include rem(font-size, 20px);
        line-height: 2rem;
        .tel {
            display: block;
            color: $white;
            @include rem(font-size, 24px);
        }
    }
}

.phone-title__wrapper {
    // Desktop
    @include mq($from: desktop) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
*/

.thumbnails {
    &__wrapper {
        display: flex;
        justify-content: space-between;
    }
    &__image {
        display: block;
        height: 50px;
        border: 2px solid transparent;
        @extend %transition;
        overflow: hidden;
        
        img {
            width: auto;
            height: 100%;
        }

        &:hover {
            border-color: $secondary2;
        }
    }

    // Desktop
    @include mq($from: desktop) {
        &__image {
            height: 75px;
        }
    }

}
.preview {
    margin-bottom: .5rem;
    img {
        display: block;
        width: 100%;
    }
}
.HomeInnerPage {
    .logo-symbol svg {
        width: 35px;
        height: 35px;
    }

    .menu__link {
        color: $secondary1;
    }

    // Desktop
    @include mq($from: desktop) {
        .navbar__logo svg {
            width: 32px;
            height: 32px;
        }
        .menu__link {
            color: $primary;
            @include rem(font-size, 14px);
        }
        .navbar__buttons {
            display: flex;
            margin-left: 8px;
        }
    }

}

.Liberar {
    .navbar__wrapper {
        .button {
            @include rem(font-size, 14px);
            @include rem(min-width, 100px);
            padding: 6px;
            height: 28px;
        }
    }
}

.menu__especial {
    display: none;
    align-items: center;
    height: 100%;
    @include rem(margin-left, 24px);

    &__link {
        @include rem(font-size, 14px);
        @include rem(line-height, 80px);
        display: block;
        font-weight: 500;
        color: $neutro;
        padding: 0 8px;
        border-bottom: 2px solid transparent;

        .active & {
            color: $primary;
            border-bottom-color: $primary;
        }
        &:hover {
            border-bottom-color: $neutro;
        }
    }

    // Desktop wider
    @include mq($from: desktop_wider) {
        display: flex;
    }
}

.phone__bar {
    display: inline-block;
    vertical-align: middle;
    text-align: center;

    .phone__text {
        margin-left: 0;
        font-size: 1rem;
        line-height: 1rem;
        color: $primary;
    }

    .phone__link {
        color: $primary;
    }

    .phone__title {
        font-size: .8rem;
    }
}

.navbar__claim {
    @include rem(font-size, 14px);
    @include rem(line-height, 16px);
    @include rem(margin-right, 16px);

    strong {
        @include rem(font-size, 24px);
    }
}
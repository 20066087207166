.footer {
    background: $secondary3;
    padding: 16px 0;
    color: $secondary1;
    text-align: center;

    a {
        display: block;
        text-decoration: none;
        color: $secondary1; 
        padding: 4px 0;
    }

    // Tablet
    @include mq($from: tablet) {
        .container {
            display: flex;
            justify-content: space-between;
        }

        .copy {
            order: -1;
        }
    }
}

.legal {
    &__list {
        border-bottom: 1px solid $secondary1;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }

    // Tablet
    @include mq($from: tablet) {
        &__list {
            border-bottom: 0;
            padding: 0;
            margin: 0;
        }
        &__item {
            display: inline-block;

            + .legal__item {
                margin-left: 16px;
                padding-left: 16px;
                border-left: 1px solid $secondary1;
            }
        }
    }
}
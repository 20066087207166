@import 'fonts';
/*----------------*/
/*-----FONTS------*/
/*----------------*/

$font-base: 'Roboto', sans-serif;

/*----------------*/
/*-----COLORS-----*/
/*----------------*/
$primary: #4774a8;
$secondary1: #b2e4fb;
$secondary2: #FBDC30;
$secondary3: #3a608c;
$neutro: #41464a;
$middle: #747474;
$grey: #bcbcbc;
$white: #FFFFFF;
$black: #010101;

// Mediaqueries
//-----------
$mobile: 20em !default; // 320px
$mobile-landscape: 30em !default; // 480px
$tablet-small: 37.5em !default; // 600px
$tablet-medium: 40em !default; // 640px
$tablet: 48em !default; // 768px
$tablet-weird: 52.5em !default; // 840px
$tablet-large: 64em !default; // 1024px
$desktop: 60em !default; // 960px
$desktop-extralarge: 85em !default; // 1360px
$desktop-max-width: 93.75em !default;



/*----------------------*/
/*------TRANSITION------*/
/*----------------------*/
%transition {
    transition: all 0.3s ease-in-out;
}
.banner__image {
    background: url('../img/bg-home-liberar.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    @include rem(height, 504px);
    @include rem(margin-bottom, 72px);

    .container {
        display: table;
        width: 100%;
        height: 100%;
    }

    &__title {
        font-weight: 700;
        color: $white;
        @include rem(font-size, 24px);
        @include rem(line-height, 32px);
        text-align: center;
        padding: 0 8px;
        @include rem(padding-bottom, 32px);
        display: table-cell;
        vertical-align: bottom;
        text-shadow: 0 2px 14px rgba(33,33,33,0.40);
    }

    // Tablet
    @include mq($from: tablet) {
        &__title {
            @include rem(font-size, 32px);
            @include rem(line-height, 40px);
            padding-left: 20%;
            padding-right: 20%;
        }
    }
    // Desktop
    @include mq($from: desktop) {
        @include rem(height, 660px);
        &__title {
            @include rem(font-size, 64px);
            @include rem(line-height, 64px);
        }
    }
}
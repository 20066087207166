.HomePage {
    .menu__link {
        color: $secondary1;
    }

    .navbar__wrapper {
        .button {
            @include rem(font-size, 14px);
            @include rem(min-width, 100px);
            padding: 6px;
            height: 28px;
        }
    }

    // Desktop
    @include mq($from: desktop) {
        .menu__list {
            display: none;
        }
    }
}
.modal {
    &__title {
        @include rem(font-size, 28px);
        font-weight: 700;
        color: $neutro;
        @include rem(margin-bottom, 24px);
    }
    &__contact {
        // display: none; //quitar luego

        background: $white;
        width: 100%;
        position: absolute;
        top: -100vh;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        z-index: 11;
        @extend %transition;

        .button__close {
            background: transparent;
        }
    }
    &__content {
        background: $white;
        @include rem(padding, 8px);
    }
    &__head {
        text-align: right;
        @include rem(margin-bottom, 16px);
    }
    &__text {
        color: $neutro;
        @include rem(margin-bottom, 24px);
    }

    // Tablet
    @include mq($from: tablet) {
        &__contact {
            left: auto;
            width: 50%;
            height: auto;
            
            &:before {
                content: "";
                display: block;
                position: fixed;
                z-index: -1;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100vw;
                height: 100vh;
                background: rgba($primary, .8);
                opacity: 0;
                pointer-events: none;
                transition: all .3s ease-in-out;

                .viewContact & {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
            
        }
        &__title {
            margin-left: 1rem;
        }
        &__content {
            box-shadow: 2px 9px 19px 0px rgba(0,0,0,0.16);

            .form__wrapper {
                margin: 0 1rem;
            }
        }
    }

    // Desktop
    @include mq($from: desktop) {
        &__contact {
            @include rem(max-width, 456px);
            bottom: auto;
        }
    }
}
.banner__form {
    background: url('../img/bg-home-rentabilizar.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    @include rem(min-height, 445px);
    @include rem(margin-bottom, 360px);
    position: relative;

    &__wrapper {
        background: $white;
        padding: 32px 8px;
        position: absolute;
        border-radius: 8px;
        box-shadow: 0 2px 16px 0 rgba(0,0,0,0.50);
        margin: 0 10px;
        @include rem(margin-top, 248px);
    }
    &__title {
        color: $middle;
        font-weight: 700;
        @include rem(font-size, 24px);
        @include rem(line-height, 32px);
        @include rem(margin-bottom, 32px);
    }
    &__text {
        text-align: center;
        @include rem(margin-bottom, 8px);
        @include rem(font-size, 16px);
        @include rem(line-height, 20px);
    }
    .button {
        width: 100%;
    }

    // Tablet
    @include mq($from: tablet) {
        @include rem(min-height, 550px);
        @include rem(margin-bottom, 40px);

        &__wrapper {
            padding: 32px 16px;
            margin-top: 0;
            width: 50%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    // Desktop 1200
    @include mq($from: desktop_wider) {
        @include rem(min-height, 659px);
        @include rem(margin-bottom, 72px);

        &__wrapper {
            @include rem(width, 456px);
        }
    }

}

.result {
    &__wrap {
        text-align: center;
        @include rem(margin-bottom, 32px);
    } 
    &__number {
        @include rem(font-size, 40px);
        font-weight: 900;
        &:after {
            content: "€";
        }
    }
    &__literal {
        @include rem(font-size, 14px);
        @include rem(line-height, 16px);
    }
}
body {
    &.sticky {
        .bar__top__buttons__mobile {
            // background: rgba($secondary3, .8);
            z-index: 10;
            /*
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            */
            .button__store {
                // opacity: 1;
                // pointer-events: auto;
            }
            .logo-symbol {
                // opacity: 1;
            }
        }
    }
    &.viewMenu {
        overflow: hidden;
        .navbar__wrapper {
            right: 0;
            z-index: 10;
            .container {
                // position: relative;
            }
        }
    }
    &.ios {
        .-ios {
            display: block;
        }
    }
    &.android {
        .-android {
            display: block;
        }
    }

    &.viewContact {
        overflow: hidden;
        .modal__contact {
            top: 0;
        }
    }

    // Tablet
    @include mq($from: tablet) {
        &.viewContact {
            // overflow: visible;
        }
    }

    // Desktop
    @include mq($from: desktop) {
        &.sticky {
            .navbar__wrapper {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: auto;
                width: 100%;
                background: rgba($secondary3, .95);
            }
            .header__wrapper {
                // padding-top: calc(190px + 4rem);
            }
            .navbar__logo svg {
                // width: 115px;
                // height: 60px;
            }
            .navbar__buttons {
                .button__store {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }
}
.block__info {
    
    &.video {
        .col + .col {
            @include rem(margin-top, 60px);
        }

        // Tablet
        @include mq($from: tablet) {
            .row {
                display: flex;
                justify-content: space-between;
            }
            .col {
                width: 32%;
            }
            .col + .col { 
                margin-top: 0;
            }
        }

    }
}
.category_item {
    max-width: 345px;
    width: 100%;
    min-width: 250px;
    height: 392px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
    border-radius: 5px;
    @include rem(margin-bottom, 16px);
    overflow: hidden;
    @extend %transition;
    
    // &:hover {
    //    transform: scale(1.1);
    //    box-shadow: 0 8px 10px 0 rgba(0,0,0,0.20);
    // }
}

.category_item .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}
.category_item .column {
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: column;
}

.category_header {
    color: $white;
}

.category_header, .category_body {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    flex-direction: 'row'; 
    justify-content: 'center'; 
    align-items: center;
    height: 196px;
}

.category_title {
    width: 100%;
    color: '#ffffff';
    font-family: Roboto; 
    font-size: 70px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    height: 100%;
}
.category_title_size_column {
    justify-content: right;
    align-items: flex-end;
}
.category_title_size {
    height: 29px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
}

.category_body .price {
    color: black;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.category_header h2 sup {
    /* Move the superscripted text up */
    top: -0.5em;
}

.category_body .price h3 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #4774a8;
}
.category_body .price h4 {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 3;
    letter-spacing: normal;
    color: #747474;
    text-align: left;
}
.category_body .price h5 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    letter-spacing: normal;
    text-align: center;
    color: #3a608c;
}

// PLANO
.plano {
    display: block;
    @include rem(margin-bottom, 16px);
    @include rem(max-width, 456px);
    img {
        width: 100%;
    }
}

$width: 24px;
$height: 5px;

// MONTADITO
.button__montadito {
    @include rem(padding, 8px);
    background: $white;
}

.montadito {
    &__wrapper {
        position: relative;
        width: $width;
        height: calc( #{$height}*5 );
        display: flex;
        align-items: center;
    }
    &__figure {
        width: $width;
        height: $height;
        background: $neutro;
        position: relative;

        &:before,
        &:after {
            content: "";
            display: block;
            width: $width;
            height: $height;
            background: $neutro;
            position: absolute;
        }

        &:before {
            top: -10px;
        }
        &:after {
            bottom: -10px;
        }
    }
    
}

// CLOSE
.button__close {
    @include rem(padding, 8px);
    background: $neutro;
    height: auto;
    /*
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    */
}

.close {
    &__wrapper {
        position: relative;
        width: $width;
        height: calc( #{$height}*5 );
        display: flex;
        align-items: center;
    }
    &__figure {
        width: $width;
        height: $height;
        background: transparent;
        position: relative;

        &:before,
        &:after {
            content: "";
            display: block;
            width: $width;
            height: $height;
            background: $grey;
            position: absolute;
        }

        &:before {
            transform: rotate(45deg);
            // top: -10px;
        }
        &:after {
            transform: rotate(-45deg);
            // bottom: -10px;
        }
    }
    
}

.navbar {
    &__wrapper {
        position: fixed;
        z-index: 1;
        background: $neutro;
        width: 100vw;
        top: 0;
        bottom: 0;
        left: auto;
        right: 100vw;
        @extend %transition;

        text-align: right;
        padding: .5rem 0;
    }
    // Desktop
    @include mq($from: desktop) {
        &__wrapper {
            width: auto;
            position: static;
            background: $white;
            // @include rem(margin-bottom, 64px);
            padding: 0;
            @include rem(height, 80px);

            .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
            }
        }
        &__logo {
            svg {
                transition: all .3s ease-in-out;
                width: 134px;
                height: 63px;
            }
            
        }
        &__buttons {
            .button__store {
                @include rem(margin-right, 16px);
                display: inline-block;

                img {
                    display: block;
                    height: 28px;
                }
                // pointer-events: none;
                // opacity: 0;

                // + .button__store {
                //    @include rem(margin-left, 16px);
                //}
            }
        }
    }
}

/*
@media screen and (max-width: 1400px) and (min-width: 992px) {
    .navbar__wrapper {
        // background: red;
        margin-bottom: 0;
        margin-top: 2rem;
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .navbar__logo {
        order: 1;
    }
    .navbar__buttons {
        order: 1;
    }
    body.sticky {
        .navbar__wrapper {
            margin-top: 0;
            .container {
                flex-direction: row;
            }
        }
        .navbar__logo {
            order: initial;
        }
        .navbar__buttons {
            order: initial;
        }
    }
}
*/

.menu {
    &__list {
        text-align: left;
    }
    &__item {

    }
    &__link {
        font-weight: 700;
        @include rem(font-size, 22px);
        text-decoration: none;
        color: $primary;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        // padding: 16px 40px;
        padding: 16px;
        display: inline-block;
        width: 100%;
    }

    // desktop
    @include mq($from: desktop) {
        &__item {
            display: inline-block;
        }
        &__link {
            font-weight: 500;
            // color: $neutro;
            @include rem(font-size, 16px);
            text-shadow: none;
            padding: 16px 8px;
            transition: all 0.3s ease-in-out;

            /*
            &:hover {
                color: $secondary2;
                background: $primary;
            }
            */
        }
    }
}
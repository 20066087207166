.section {
    padding: 48px 0;
    + .section {
        // padding-bottom: 48px;
    }

    &__title {
        @include rem(font-size, 32px);
        font-weight: 700;
        color: $secondary1;
        text-align: center;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        @include rem(margin-bottom, 18px);
    }
    &__subtitle {
        font-weight: 700;
        @include rem(font-size, 20px);
        @include rem(line-height, 30px);
        @include rem(margin-bottom, 18px);
    }

    &__video {
        // background: url('../img/bg-video.png') no-repeat center;
        // background-size: contain;

        .col + .col {
            @include rem(margin-top, 60px);
        }
    }

    &__rules-security {
        .col + .col {
            @include rem(margin-top, 40px);
        }
    }

    &__banner {
        text-align: center;
        background: $secondary3;
        padding: 16px 0 0 0;

        .section__title {
            line-height: 2.5rem;
            margin-bottom: 2rem;
        }
        .price {
            @include rem(font-size, 60px);
            margin-left: 4px;
        }
        small {
            @include rem(font-size, 10px);
        }
        .buttons__wrapper {
            @include rem(margin-bottom, 30px);
        }
    }

    &__franquicia {
        background: url('../img/bg-franquiciados.png') no-repeat center;
        background-size: cover;
        @include rem(min-height, 345px);
        position: relative;
        z-index: 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(#102f52,.8);
        }
    }

    // Tablet
    @include mq($from: tablet) {
        padding: 56px 0;

        &__title {
            @include rem(font-size, 32px);
            @include rem(margin-bottom, 40px);
        }
        &__video {
            .row {
                display: flex;
                justify-content: space-between;
            }
            .col {
                width: 32%;
            }
            .col + .col { 
                margin-top: 0;
            }
        }
        &__banner {
            @include rem(padding-top, 24px);
            padding-bottom: 0;
            margin: 72px 0 0;
            text-align: left;
    
            .container {
                position: relative;
            }

            .section__title,
            .buttons__wrapper {
                text-align: left;
            }

            .section__title {
                @include rem(margin-bottom, 16px);
            }

            .buttons__wrapper {
                @include rem(padding-bottom, 24px);
                margin-bottom: 0;
            }

            .banner__image {
                position: absolute;
                @include rem(right, 24px);
                bottom: 0;
            }
        }

        &__rules-security {
            .row {
                display: flex;
            }
            .col {
                width: 50%;

                + .col {
                    margin-top: 0;
                }
            }
            .section__title {
                text-align: left;
            }
        }

        &__contact {
            .row {
                display: flex;
                justify-content: space-between;
            }
            .col {
                width: 48%;
            }
            .email {
                margin-top: 0;
            }
        }
    }

    // Desktop
    @include mq($from: desktop) {

        &__title {
            @include rem(font-size, 48px);
            @include rem(margin-bottom, 48px);
        }

        &__subtitle {
            @include rem(font-size, 28px);
        }
        &__video {
            // background: none;
            position: relative;
            @include rem(margin-bottom, 150px);

            /*
            .container {
                max-width: 940px;
            }
            */

            // &:before {
                // content: url('../img/bg-video.png');
                // position: absolute;
                // z-index: -1;
                // top: 25%;
            // }

            // background-size: cover; 
        }

        &__banner {
            @include rem(padding-top, 32px);

            .container {
                max-width: 940px;
            }
            .section__title {
                margin-bottom: 1.5rem;
            }
            .banner__image {
                right: 0;
            }
            .buttons__wrapper {
                @include rem(padding-bottom, 32px);
            }
        }

        &__how {
            .container {
                > .row {
                    display: flex;
                    justify-content: space-between;

                    /*
                    > .col {
                        width: 48%;
                    }
                    */
                }
                .col {
                    &:first-child {
                        width: 48%;
                        max-width: 460px;
                        flex: 0 0 auto;
                    }
                    &:not(:first-child) {
                        width: 55%;
                    }
                }
            }
        }

        &__rules-security {
            padding-top: 80px;
        }

        &__contact {
            .section__subtitle {
                @include rem(font-size, 32px);
                @include rem(line-height, 48px);
            }
        }
    }
}
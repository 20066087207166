.block__list {
    counter-reset: section;
    list-style-type: none;

    &__item {
        &:not(:last-child){
            @include rem(margin-bottom, 40px);
        }

        &__title {
            display: flex;
            align-items: center;
            @include rem(font-size, 24px);
            @include rem(line-height, 32px);
            font-weight: 700;
            @include rem(margin-bottom, 16px);

            &:before {
                counter-increment: section;
                content: counters(section,".") " ";
                display: inline-block;
                color: $white;
                font-weight: 700;
                @include rem(font-size, 24px);
                padding: 1rem 1.6rem;
                border-radius: 50%;
                background: $primary;
                @include rem(margin-right, 16px);
            }
        }
    }

    // Desktop 1200
    @include mq($from: desktop_wider) {
        display: flex;
        justify-content: space-between;

        &__item {
            width: 33%;
        }
    }
}

.block__column {
    &__image {
        display: none;
    }

    // Desktop 1200
    @include mq($from: desktop_wider) {
        display: flex;

        &__image {
            display: block;
        }
        &__content {
            @include rem(margin-left, 140px);
        }
    }
}
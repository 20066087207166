.header {
    &__wrapper {
        
        /*
        background: url('../img/bg-header.png') no-repeat center;
        background-size: cover;
        position: relative;
        z-index: 2;
        @include rem(padding-top, 150px);
        @include rem(padding-bottom, 80px);

        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(#102f52,.8);
        }

        body.InnerPage & {
            background: none;
            padding-bottom: 0;
            &:before {
                display: none;
            }
        }
        */
    }

    // Desktop
    @include mq($from: desktop) {
        &__wrapper {
            // @include rem(padding-top, 60px);
        }
    }
}

.bar__top {
    background: rgba($neutro, 0);
    // @include rem(padding, 8px);
    // position: relative;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &__wrapper {
        // display: flex;
        // justify-content: flex-end;

        &.container {
            padding: 0;
        }
    }

    &__buttons__mobile {
        background: $white;
        @include rem(padding, 8px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @extend %transition;

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;

        .button__store {
            display: none;
            @extend %transition;

            img {
                @include rem(max-width, 80px);
            }
        }
        .logo-symbol {
            // opacity: 0;
            @extend %transition;
        }
    }

    // Desktop
    @include mq($from: desktop) {
        // @include rem(padding, 16px);
        // background: $neutro;

        &__wrapper {
            position: relative;
        }
    }
}

.items {
    &__desktop {
        display: none;
    }

    // Desktop
    @include mq($from: desktop) {
        &__mobile {
            display: none;
        }
        &__desktop {
            // display: block;
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}

.bar__top__buttons__desktop {
    text-align: right;
}